<script setup lang="ts">
interface Props {
  text: string;
  url?: string;
}

withDefaults(defineProps<Props>(), { url: '', })

const linkComponent = computed(() => (__props.url
  ? __props.url.startsWith('http')
    ? 'a'
    : 'NuxtLink'
  : 'button'));

const binds = computed(() => (__props.url
  ? __props.url.startsWith('http')
    ? { href: __props.url }
    : { to: { path: __props.url } }
  : {}
));
</script>

<template>
  <component
    :is="linkComponent"
    class="button flex cursor-pointer items-center justify-center rounded-lg bg-green-dark text-center text-sm font-bold text-white no-underline hover:bg-green"
    v-bind="binds"
  >
    <span>{{ text }}</span>
  </component>
</template>
